"use client";

import React from "react";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent } from "@/components/ui/dropdown-menu";
import { Button, buttonVariants } from "@/components/ui/button";
import { DropdownMenuArrow } from "@radix-ui/react-dropdown-menu";
import ReportButton from "../shared/ReportButton";
import ShareButton from "../shared/ShareButton";
import Link from "next/link";
import { LucideDelete, LucideEdit3 } from "lucide-react";
import { useUser } from "@/contexts/user";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { useMutation } from "@apollo/client";
import { DeleteReviewDocument } from "@/graphql/generated/types";
interface ReviewActionsMenuProps {
  id: string;
  isMine: boolean;
  shareUrl: string;
  reviewAuthorName: string;
}
const ReviewActionsMenu: React.FC<ReviewActionsMenuProps> = ({
  id,
  isMine,
  shareUrl,
  reviewAuthorName
}) => {
  const {
    user
  } = useUser();
  const [deleteReview] = useMutation(DeleteReviewDocument);
  const handleDeleteReview = async () => {
    try {
      await deleteReview({
        variables: {
          id
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  isMine = !!user && isMine;
  const editReviewUrl = `${shareUrl.split("/").slice(0, -1).join("/")}/new`;
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="ReviewActionsMenu" data-sentry-source-file="ReviewActionsMenu.tsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="ReviewActionsMenu.tsx">
        <Button variant="link" className="p-0 cursor-pointer" data-sentry-element="Button" data-sentry-source-file="ReviewActionsMenu.tsx">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ReviewActionsMenu.tsx">
            <path d="M2.85345 8.19828C2.85345 7.89357 2.60643 7.64655 2.30172 7.64655C1.99702 7.64655 1.75 7.89357 1.75 8.19828C1.75 8.50298 1.99702 8.75 2.30172 8.75C2.60643 8.75 2.85345 8.50298 2.85345 8.19828Z" stroke="white" strokeWidth="2.22997" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
            <path d="M8.36907 8.19828C8.36907 7.89357 8.12206 7.64655 7.81735 7.64655C7.51264 7.64655 7.26562 7.89357 7.26562 8.19828C7.26562 8.50298 7.51264 8.75 7.81735 8.75C8.12206 8.75 8.36907 8.50298 8.36907 8.19828Z" stroke="white" strokeWidth="2.22997" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
            <path d="M13.8847 8.19828C13.8847 7.89357 13.6377 7.64655 13.333 7.64655C13.0283 7.64655 12.7812 7.89357 12.7812 8.19828C12.7812 8.50298 13.0283 8.75 13.333 8.75C13.6377 8.75 13.8847 8.50298 13.8847 8.19828Z" stroke="white" strokeWidth="2.22997" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
          </svg>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center" sideOffset={-10} className="w-[176px] border border-[#bdbdbd]/10 bg-[#1d1e2f] p-0" data-sentry-element="DropdownMenuContent" data-sentry-source-file="ReviewActionsMenu.tsx">
        <DropdownMenuArrow height={18} width={30} className="fill-[#1d1e2f]" data-sentry-element="DropdownMenuArrow" data-sentry-source-file="ReviewActionsMenu.tsx" />

        {isMine && <>
            <Link href={editReviewUrl} className={buttonVariants({
          variant: "secondary",
          className: "w-full bg-transparent h-full pl-3 pr-[37px] flex items-center text-sm gap-[13px] py-4 cursor-pointer"
        })}>
              <LucideEdit3 size={18} />
              Edit Review
            </Link>

            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="secondary" className="w-full bg-transparent h-full pl-5 pr-[37px] flex items-center text-sm gap-[13px] py-4 cursor-pointer">
                  <LucideDelete size={18} />
                  Delete Review
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Are you sure you want to delete your review?
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDeleteReview}>
                    Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>}

        {/* Share Review Dialog */}
        <ShareButton shareUrl={shareUrl} data-sentry-element="ShareButton" data-sentry-source-file="ReviewActionsMenu.tsx" />

        {/* Report Review Dialog */}

        {!isMine && <ReportButton reportType="review" reportedEntityName={reviewAuthorName} reportedEntityUrl={shareUrl} />}
      </DropdownMenuContent>
    </DropdownMenu>;
};
export default ReviewActionsMenu;