"use client";

import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import StatusDropdown from "./StatusDropdown";
import { Book, ReadingStatusEnum } from "@/graphql/generated/types";
import useSetReadingStatus from "@/hooks/useSetReadingStatus";
import { getStatusLabel, getStatusIcon } from "@/utils/statusUtils";
import { toast } from "sonner";
const AddToShelfButton = ({
  initialStatus,
  bookId,
  className
}: {
  initialStatus?: ReadingStatusEnum;
  bookId: Book["id"];
  className?: string;
}) => {
  const [status, setStatus] = useState<ReadingStatusEnum | null>(initialStatus || null);
  const {
    setStatus: setReadingStatus
  } = useSetReadingStatus([bookId as string]);
  const handleButtonClick = async () => {
    if (!status) {
      const newStatus = ReadingStatusEnum.WantToRead;

      // Optimistic UI update
      setStatus(newStatus);
      toast.success(`Book has been marked as "${getStatusLabel(newStatus)}".`, {
        style: {
          backgroundColor: "#5069ce",
          color: "white",
          fontSize: "16px"
        }
      });
      try {
        await setReadingStatus(newStatus);
      } catch (e) {
        // Revert status if there was an error

        if (e instanceof Error) {
          setStatus(null);
          toast.error("Failed to update status. Please try again.", {
            style: {
              backgroundColor: "#ff4d4f",
              color: "white",
              fontSize: "16px"
            }
          });
        }
      }
    } else {
      toast(`Book is already marked as "${getStatusLabel(status)}".`, {
        style: {
          backgroundColor: "#5069ce",
          color: "white",
          fontSize: "16px"
        }
      });
    }
  };
  const statusLabel = getStatusLabel(status || ReadingStatusEnum.WantToRead);
  const statusIcon = getStatusIcon(status || ReadingStatusEnum.WantToRead, true); // White icon

  return <div className={cn("flex h-[46px] rounded-[9px] bg-[#5069c3]", className)} data-sentry-component="AddToShelfButton" data-sentry-source-file="AddToShelfButton.tsx">
      <Button type="button" variant="primary" className="h-full rounded-l-[8px]  flex-1 rounded-r-none bg-inherit py-3 pl-4 pr-[21px] text-base font-medium sm:font-normal leading-[22px] text-white" onClick={handleButtonClick} data-sentry-element="Button" data-sentry-source-file="AddToShelfButton.tsx">
        {statusIcon}
        <span style={{
        filter: "drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25))"
      }} className="ml-2 text-base text-white">
          {statusLabel}
        </span>
      </Button>
      <StatusDropdown initialStatus={status} bookIds={[bookId]} onStatusChange={newStatus => {
      setStatus(newStatus);
      toast.success(`Set status to "${getStatusLabel(newStatus)}".`, {
        style: {
          backgroundColor: "#5069ce",
          color: "white",
          fontSize: "16px"
        }
      });
    }} trigger={<Button type="button" variant="primary" className="flex w-10 h-full items-center rounded-l-none rounded-r-[8px] border-l border-l-white/40 bg-inherit px-4 py-3">
            <svg width="8" height="22" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2117_800)">
                <path d="M0 9.16667L4 13.75L8 9.16667H0Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2117_800">
                  <rect width="22" height="8" fill="white" transform="matrix(0 -1 1 0 0 22)" />
                </clipPath>
              </defs>
            </svg>
          </Button>} data-sentry-element="StatusDropdown" data-sentry-source-file="AddToShelfButton.tsx" />
    </div>;
};
export default AddToShelfButton;