/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from "@apollo/client"
import { useState } from "react"

const LIKE_REVIEW = gql`
  mutation LikeReview($reviewId: ID!) {
    likeReview(reviewId: $reviewId)
  }
`

const UNLIKE_REVIEW = gql`
  mutation UnlikeReview($reviewId: ID!) {
    unlikeReview(reviewId: $reviewId)
  }
`

const LIKE_REVIEW_COMMENT = gql`
  mutation LikeReviewComment($reviewCommentId: ID!) {
    likeReviewComment(reviewCommentId: $reviewCommentId)
  }
`

const UNLIKE_REVIEW_COMMENT = gql`
  mutation UnlikeReviewComment($reviewCommentId: ID!) {
    unlikeReviewComment(reviewCommentId: $reviewCommentId)
  }
`

export const useLikeResource = ({
  resourceType,
  resourceId,
  initialLiked,
  initialLikesCount = 0,
}: any) => {
  const [liked, setLiked] = useState(initialLiked)
  const [likesCount, setLikesCount] = useState(initialLikesCount)

  // // Define mutations unconditionally
  const [likeReview] = useMutation(LIKE_REVIEW, {})
  const [unlikeReview] = useMutation(UNLIKE_REVIEW, {})
  const [likeReviewComment] = useMutation(LIKE_REVIEW_COMMENT, {})
  const [unlikeReviewComment] = useMutation(UNLIKE_REVIEW_COMMENT, {})
  const toggleLike = async () => {
    try {
      if (resourceType === "review") {
        if (liked) {
          await unlikeReview({
            variables: { reviewId: resourceId },
            optimisticResponse: {
              unlikeReview: {
                __typename: "UnlikeReviewPayload",
                success: true,
                likesCount: likesCount - 1,
              },
            },
          })
          setLikesCount((prev: any) => prev - 1)
        } else {
          await likeReview({
            variables: { reviewId: resourceId },
            optimisticResponse: {
              likeReview: {
                __typename: "LikeReviewPayload",
                success: true,
                likesCount: likesCount + 1,
              },
            },
          })
          setLikesCount((prev: any) => prev + 1)
        }
      } else if (resourceType === "reviewComment") {
        if (liked) {
          await unlikeReviewComment({
            variables: { reviewCommentId: resourceId },
            optimisticResponse: {
              unlikeReviewComment: {
                __typename: "UnlikeReviewCommentPayload",
                success: true,
                likesCount: likesCount - 1,
              },
            },
          })
          setLikesCount((prev: any) => prev - 1)
        } else {
          await likeReviewComment({
            variables: { reviewCommentId: resourceId },
            optimisticResponse: {
              likeReviewComment: {
                __typename: "LikeReviewCommentPayload",
                success: true,
                likesCount: likesCount + 1,
              },
            },
          })
          setLikesCount((prev: any) => prev + 1)
        }
      } else {
        throw new Error(`Unsupported resourceType: ${resourceType}`)
      }
      setLiked(!liked)
    } catch (error) {
      console.error("Error toggling like:", error)
    }
  }

  return { liked, likesCount, toggleLike }
}
