"use client";

import { useResponsive } from "@/hooks/useResponsive";
import { cn } from "@/lib/utils";
import { useState, useMemo } from "react";
import DOMPurify from "isomorphic-dompurify";
import { generateHTML } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import truncate from "truncate-html";
import Image from "@tiptap/extension-image";
import Blockquote from "@tiptap/extension-blockquote";
import { Review } from "@/graphql/generated/types";
const ReadMoreText = ({
  isReview,
  text,
  contentJSON,
  mobileLength = 330,
  desktopLength = 794,
  className
}: {
  isReview?: boolean;
  text?: string;
  contentJSON?: Review["content"];
  mobileLength?: number;
  desktopLength?: number;
  className?: string;
}) => {
  const {
    isMobile
  } = useResponsive();
  const [isExpanded, setIsExpanded] = useState(false);
  const displayLength = isMobile ? mobileLength : desktopLength;
  const {
    displayedContent,
    isTruncated
  } = useMemo(() => {
    if (isReview && contentJSON) {
      // Handle Tiptap content
      // Generate HTML from the Tiptap content
      const html = generateHTML(contentJSON, [StarterKit.configure({
        bulletList: false,
        orderedList: false,
        listItem: false,
        blockquote: false
      }), Underline, Highlight.configure({
        multicolor: false,
        HTMLAttributes: {
          style: "background-color: #0b7285; color: inherit;"
        }
      }), ListItem, BulletList, OrderedList, Image, Blockquote]);

      // Sanitize the HTML
      const sanitizedHTML = DOMPurify.sanitize(html);
      let displayedContent = sanitizedHTML;
      let isTruncated = false;
      if (!isExpanded) {
        // Truncate the HTML content
        const truncated = truncate(sanitizedHTML, displayLength, {
          ellipsis: "..."
        });
        displayedContent = truncated;
        isTruncated = truncated.length < sanitizedHTML.length;
      }
      return {
        displayedContent,
        isTruncated
      };
    } else if (text) {
      // Handle plain text
      let displayedContent = text;
      let isTruncated = false;
      if (!isExpanded && text.length > displayLength) {
        displayedContent = text.slice(0, displayLength) + "...";
        isTruncated = true;
      }
      return {
        displayedContent,
        isTruncated
      };
    } else {
      return {
        displayedContent: null,
        isTruncated: false
      };
    }
  }, [isReview, contentJSON, text, isExpanded, displayLength]);
  if (!displayedContent) return null;
  return <div className={cn("text-sm font-normal text-[#E6E6FA]/90", className)} data-sentry-component="ReadMoreText" data-sentry-source-file="ReadMoreText.tsx">
      {isReview ? <div className="max-sm:-mb-1 prose prose-invert  max-w-none [&_ol]:list-decimal [&_ul]:list-disc sm:mb-4 text-sm font-normal text-[#E6E6FA]/90" dangerouslySetInnerHTML={{
      __html: displayedContent
    }} /> : <p className="text-sm whitespace-pre-line">{displayedContent}</p>}
      {!isExpanded && isTruncated && <span onClick={() => setIsExpanded(true)} className="cursor-pointer inline-block underline ">
          Read More
        </span>}
    </div>;
};
export default ReadMoreText;